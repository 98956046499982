/* eslint-disable */
import WSPGallery from '../components/WSPGallery';
import g1 from "../assets/g1.webp";
import g2 from "../assets/g2.webp";
import g7 from "../assets/g7.webp";
import g8 from "../assets/g8.webp";
import g9 from "../assets/g9.webp";
import g10 from "../assets/g10.webp";
import g3 from "../assets/g3.webp";
import g4 from "../assets/g4.webp";
import g5 from "../assets/g5.webp";
import g6 from "../assets/g6.webp";
import g11 from "../assets/g11.webp";
import g12 from "../assets/g12.webp";
import g13 from "../assets/g13.webp";
import g14 from "../assets/g14.webp";
import g15 from "../assets/g15.webp";
import g16 from "../assets/g16.webp";
import g17 from "../assets/g17.webp";
import g18 from "../assets/g18.webp";
import g19 from "../assets/g19.webp";
import g20 from "../assets/g20.webp";
import my1 from "../assets/my1.webp";
import my2 from "../assets/my2.webp";
import my3 from "../assets/my3.webp";
import my7 from "../assets/my7.webp";
import f2 from "../assets/f2.webp";
import f3 from "../assets/f3.webp";
import Footer from '../components/Footer';
import './Gallery.css';
import { Helmet } from 'react-helmet';

const Gallery = () => {

  const marriageImages = [
    { img: g1 },
    { img: g6 },
    { img: g19 },
    { img: my1 }
  ];

  const birthdayImages = [
    { img: g14 },
    { img: g15 },
    { img: g16 },
    { img: my7 }
  ];

  const aromaImages = [
    { img: g2 },
    { img: g3 },
    { img: g5 },
    { img: g4 },
    { img: g7 },
    { img: g13 },
    { img: g18 },
    { img: g20 },
  ];

  const aromaCatering = [
    { img: g8 },
    { img: g9 },
    { img: g10 },
    { img: g11 },
    { img: g12 },
    { img: g17 },
    { img: f2 },
    { img: f3 }
  ]

  const suvecha = [
    { img: my1 },
    { img: my2 },
    { img: my3 },
    { img: g19 },

  ]

  return (
    <div>
      <Helmet>
      <title>Aroma Gallery - Kolkata , Behala | Banquet Hall</title>
          <meta name='description' content='See photos of Aroma,Suvecha and catering services near behala,chowrastra,sakherbazaar,Kolkata'/>
          <link rel="canonical" href={window.location.href} />
      </Helmet>
      <WSPGallery galleryImages={marriageImages} section="Marriage" />
      <WSPGallery galleryImages={birthdayImages} section="Birthday" />
      <WSPGallery galleryImages={aromaImages} section="Aroma" />
      <div className="button-container">
        <a href="https://www.google.com/maps/uv?pb=!1s0x3a027a6a859e740b%3A0xc2a3d2e855f3c9f1!5sAroma%20Festive%20Hall!15sCgIgARICEAE&imagekey=!1e10!2sAF1QipNqKsaupDUxBDWFazhS90fgDxmgcrmT_wkP84qD" className="red-button">For More pics</a>
    </div>
      <WSPGallery galleryImages={suvecha} section="Suvecha" />
      <div className="button-container">
        <a href="https://www.google.com/maps/uv?pb=!1s0x3a027bf042a845ef%3A0x6f5ad9dcb9f520dd!5sSuvecha%20Cerimonial%20House!15sCgIgARICEAE" className="red-button">For More pics</a>
    </div>
      <WSPGallery galleryImages={aromaCatering} section="Catering" />
      <div className="button-container">
        <a href="https://www.google.com/maps/uv?pb=!1s0x3a027b9f68e882a7%3A0x382d491649035b8b!5sAroma%20Caterer%20and%20Decorator!15sCgIgARICEAE" className="red-button">For More pics</a>
    </div>
    <div className="back"><br/></div>
      <Footer />
    </div>
  );
}
export default Gallery;
