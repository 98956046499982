import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Gallery from './pages/Gallery';
import NavTop from './components/NavTop';
import Slider from './pages/Home';
import {
  BrowserRouter as Router,
  Routes,
  Route,

} from "react-router-dom";
import Menu from './pages/Menu';
import About from './pages/T&C';
import Book from './pages/Book';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ScrollToTop from './components/ScrollToTop';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga";
import { useEffect } from 'react';
const TRACKING_ID="UA-262569260-1";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <Router>
        <ScrollToTop/>
        <NavTop />
        <Helmet>
          <title>Aroma Caterer And Decorators - Kolkata , Behala | Banquet Hall</title>
          <meta name='description' content='Aroma Caterer And Decorators banquet hall catering services for weddings, corporate events, and other special occasions near behala chowrastra , kolkata.'/>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Routes>
          <Route exact path="/" element={<Slider />}></Route>
          <Route path="/home" element={<Slider />} />
          <Route path="/Menu" element={<Menu />} />
          <Route path="/Gallery" element={<Gallery/>} />
          <Route path="/About" element={<About />} />
          <Route path="/Book" element={<Book />} />
        </Routes>
      </Router>
      <div className="App">
        <TawkMessengerReact
          propertyId="642eb3eb31ebfa0fe7f6d764"
          widgetId="1gtb6tl9i" />
      </div>
    </>
  );
}

export default App;


