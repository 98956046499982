/* eslint-disable */
import React from 'react'
import { NavLink } from 'react-router-dom'; // import NavLink instead of Link
import "./NavTop.css";
import Aroma123 from "../assets/AROMA123.png";

export default function NavTop() {
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (windowSize > 768) {
    return (
      <>
        <nav style={{ display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "#f5a300d3", position: "sticky", top: 0, zIndex: 100, padding: "10px 20px", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)" }}>
          <div style={{ display: "flex", alignItems: "center", fontSize: 24, fontWeight: "bold", textTransform: "uppercase" }}>
            <NavLink to="/home" style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "black" }}>
              <img src={Aroma123} alt="Aroma" height="50" width="100" style={{ marginRight: 10 }} />
              <b>AROMA</b>
            </NavLink>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <NavLink to="/home" style={({ isActive }) => ({ fontSize: 18, color:"black" , textDecoration: isActive ? "underline" : "none", marginRight: 20 ,fontWeight: isActive ? 'bold' : 'normal',})} >Home</NavLink>
            <NavLink to="/menu" style={({ isActive }) => ({ fontSize: 18, color:  "black" , textDecoration: isActive ? "underline" : "none", marginRight: 20,fontWeight: isActive ? 'bold' : 'normal', })} >Menu</NavLink>
            <NavLink to="/gallery" style={({ isActive }) => ({ fontSize: 18, color:  "black", textDecoration: isActive ? "underline" : "none", marginRight: 20 ,fontWeight: isActive ? 'bold' : 'normal',})} >Gallery</NavLink>
            <NavLink to="/about" style={({ isActive }) => ({ fontSize: 18, color:  "black", textDecoration: isActive ? "underline" : "none", marginRight: 20 ,fontWeight: isActive ? 'bold' : 'normal',})}>T&C</NavLink>
          </div>
          <div>
            <NavLink to="/book" style={{ textDecoration: "none" }}>
              <button style={{ fontSize: 18, backgroundColor: "#F04401", borderRadius: 20, border: "1px solid #F04401", color: "#fff", padding: "10px 20px", cursor: "pointer" }}>Book now</button>
            </NavLink>
          </div>
        </nav>
      </>
    )
  } else {
    return (
      <nav style={{ backgroundColor: '#f5a300d3', position: 'sticky', top: 0, zIndex: 100 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px' }}>
          <NavLink to="/home" style={{ textDecoration: 'none', fontWeight: 'bold', color: 'black' }}>
            AROMA
          </NavLink>
          <div>
            <NavLink to="/book" style={{ textDecoration: 'none' }}>
              <button style={{ fontSize: '18px', backgroundColor: '#F04401', borderRadius: '20px', border: '1px solid #F04401', color: '#fff' }}>
                Book now
              </button>
            </NavLink>
          </div>
        </div>
        <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0, justifyContent: 'space-evenly', padding: '10px 20px' }}>
          <li style={{ marginRight: '20px' }}>
            <NavLink to="/home" style={({ isActive }) => ({
              textDecoration: isActive ? 'underline' : 'none',
              fontWeight: isActive ? 'bold' : 'normal',
              // color: isActive ? 'red' : 'black'
              color: 'black'
            })}>
              Home
            </NavLink>
          </li>
          <li style={{ marginRight: '20px' }}>
            <NavLink to="/menu" style={({ isActive }) => ({
              textDecoration: isActive ? 'underline' : 'none',
              fontWeight: isActive ? 'bold' : 'normal',
              // color: isActive ? 'red' : 'black'
              color: 'black'
            })}>
              Menu
            </NavLink>
          </li>
          <li style={{ marginRight: '20px' }}>
            <NavLink to="/gallery" style={({ isActive }) => ({
              textDecoration: isActive ? 'underline' : 'none',
              fontWeight: isActive ? 'bold' : 'normal',
              // color: isActive ? 'red' : 'black'
              color: 'black'
            })}>
              Gallery
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" style={({ isActive }) => ({
              textDecoration: isActive ? 'underline' : 'none',
              fontWeight: isActive ? 'bold' : 'normal',
              // color: isActive ? 'red' : 'black'
              color: 'black'
            })}>
              T&C
            </NavLink>
          </li>
        </ul>
      </nav>
    )
  }
}
