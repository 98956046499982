import "./Footer.css";

function Footer() {
    return (
      <footer className="footer">
        <div className="container" >
          <span className="text-muted">© {new Date().getFullYear()} Aroma Caterer An Decorator</span>
        </div>
      </footer>
    );
  }
  
  export default Footer;
  //ChIJC3SehWp6AjoR8cnzVejSo8I
  //ChIJC3SehWp6AjoR8cnzVejSo8I